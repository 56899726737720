import matchtitle from '../../components/matchtitle';
import {
	handlePageQueryGroupsettingy
} from '@index/api/mymatchs/groupsetitem';
import {
	Message
} from 'element-ui'
export default {
	name: 'joinmatchonlinesignuptable',
	components: {
		matchtitle
	},
	data() {
		return {
			matchRules:['等级赛','名次赛','奖金赛','赛制不限','淘汰赛','精英赛','竞技赛','进阶赛'],
			genderRequirements: ["不限", "男", "女", "男女", "女女"],
			types: ["双人组", "多人组", "单人组", "不限"],
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			matchId: sessionStorage.getItem('joinMatchId'),
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryGroupsettingy({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				matchId: this.matchId,
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					})
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},

	}
}
