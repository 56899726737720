import request from '@index/utils/request'

export function handlePageQueryGroupsettingy(data) { //分页查询
	return request({
		url: 'groupsetting/pageQueryGroupsetting',
		method: 'post',
		data
	})
}


export function handleDeleteGroupsetting(matchId) { //删除组别设项
	return request({
		url: 'groupsetting/deleteGroupsetting',
		method: 'post',
		params: {
			matchId: matchId
		}
	})
}

export function handleSave(data) { //保存组别设项（行数据）
	return request({
		url: 'groupsetting/save',
		method: 'post',
		data
	})
}

export function handleAgeControll(data) { //启用停用年龄控制
	return request({
		url: 'groupsetting/ageControll',
		method: 'post',
		data
	})
}



export function handleDownload(matchId) { //启用停用年龄控制
	return request({
		url: 'groupsetting/download',
		method: 'post',
		responseType: 'blob',
		params: {
			matchId: matchId
		}
	})
}


export function handleSetGroupsetttingSignupStatus(data) { //设置组别报名状态
	return request({
		url: 'groupsetting/setGroupsetttingSignupStatus',
		method: 'post',
		data
	})
}

export function handleaAddGroup(data) { //添加组别
	return request({
		url: 'groupsetting/addGroup',
		method: 'post',
		data
	})
}

